import React from 'react'

import Logo from './logo'
import Menu from './menu'

import HeaderStyles from './header.module.scss'


const Header = () => {
	return (
		<header className={HeaderStyles.header}>
			<Logo />
			<Menu />
		</header>
	)
}

export default Header