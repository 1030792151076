import React from 'react'
import '../styles/index.scss'
import ContentContainStyles from './contentContain.module.scss'



const ContentContain = (props) => {
	return (
		<div className={ContentContainStyles.contentWrapper}>
			{props.children}	
		</div>
	)
}

export default ContentContain