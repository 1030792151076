import React from 'react'
import { Link } from 'gatsby'

import logoStyles from './logo.module.scss'

const Logo = () => {
	return (
		<Link to="" className={logoStyles.logoWrapper}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 160">
				<path d="M83.2 26H51.6L19.7 133.2h31.6zM147.1 26h-31.6L83.6 133.2h31.6zM163.3 80.2l-15.9 53H179z"/>
			</svg>
		</Link>
	)
}

export default Logo