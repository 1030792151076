import React from 'react'

import MenuBtnStyles from './menuBtn.module.scss'


const MenuBtn = (props) => {
	return (
		<div className={props.isOpen ? MenuBtnStyles.menuBtnWrapper : MenuBtnStyles.menuBtnWrapperClosed} >
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 160">
				<path d="M26.8 32H173"/>
				<path d="M26.8 80.7H173"/>
				<path d="M26.8 129.4H173"/>
			</svg>
		</div>
	)
}

export default MenuBtn