import React from 'react'
import { Link } from 'gatsby'

import MenuListStyles from './menuList.module.scss'


const MenuList = (props) => {
	return (
		<nav >
			<ul className={props.isOpen ? MenuListStyles.menuListWrapper : MenuListStyles.menuListWrapperClosed}>
				<li>
					<Link to="/about">About</Link>
				</li>
				<li>
					<Link to="/work">Work</Link>
				</li>
				<li>
					<Link to="/contact">Contact</Link>
				</li>
			</ul>
		</nav>
	)
}

export default MenuList