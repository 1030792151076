import React from 'react'


import FooterStyles from './footer.module.scss'


const Footer = () => {
	return (
		<footer className={FooterStyles.footer}>
			<p>&copy; Matthew Mallory 2019</p>
		</footer>
	)
}

export default Footer