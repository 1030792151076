import React, { Component } from 'react'
import MenuBtn from './menuBtn'
import MenuList from './menuList'

import MenuStyles from './menu.module.scss'

class Menu extends Component {

	constructor(props) {
		super(props)
		this.state = {
			open: false
		}
	}

	toggleMenu = () => {
		this.setState(prevState => ({ open: !prevState.open }));
	}

	render() {

		const { open } = this.state;

		return (
			<div className={MenuStyles.menu}>
				<button onClick={this.toggleMenu} >
					<MenuBtn isOpen={open} />
				</button>
				<MenuList isOpen={open} />
			</div>
		)
	}

}


export default Menu