import React from 'react'

import Header from '../components/header'

import '../styles/index.scss'

import layoutStyles from './layout.module.scss'



const Layout = (props) => {
	return (
		<div className={layoutStyles.layout}>
			<Header />
			<div>
				{props.children}
			</div>

		</div>
	)
}

export default Layout