import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import ContentContain from '../components/contentContain'
import Footer from '../components/footer'
import IndexStyles from './index.module.scss'
import IntegrifyCard from './images/Integrify-thumb.jpg'
import LogiwaCard from './images/Logiwa-thumb.jpg'
import CellcontrolCard from './images/Cellcontrol-thumb.jpg'
import OffsiteCard from './images/Offsite-thumb.jpg'
import NovaerusCard from './images/Nov-thumb.jpg'


const IndexPage = () => {
	return (
		<Layout>
			<ContentContain>
				<div className={IndexStyles.indexHero}>
					<h1>Hello, My Name is Matthew Mallory, I’m a Freelance Creative</h1>
				</div>
				<div className={IndexStyles.indexAbout}>
					<h2>Multidisciplinary</h2>
					<p>I have been working as a Freelance Creative for over a decade now, I have a wide array of skills that allow mme to bring a unique perspective  to my project</p>
				</div>			
			</ContentContain>
			<ContentContain>
				<div className={IndexStyles.indexSectionHeader}>
					<h2>My Work</h2>
				</div>			
			</ContentContain>
			<div className={IndexStyles.indexWork}>
			    <div className={IndexStyles.card}>
			    	<img src={IntegrifyCard} alt="" />
			    	<h4>Integrify Brand & Video</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={LogiwaCard} alt="" />
			    	<h4>Logiwa Branding</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={CellcontrolCard} alt="" />
			    	<h4>Cellcontrol Videos</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={OffsiteCard} alt="" />
			    	<h4>Offsite Branding</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={NovaerusCard} alt="" />
			    	<h4>Novaerus Video + Animation</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={IntegrifyCard} alt="" />
			    	<h4>Integrify Brand & Video</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={LogiwaCard} alt="" />
			    	<h4>Logiwa Branding</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={CellcontrolCard} alt="" />
			    	<h4>Cellcontrol Videos</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={OffsiteCard} alt="" />
			    	<h4>Offsite Branding</h4>
			    </div>
			    <div className={IndexStyles.card}>
			    	<img src={NovaerusCard} alt="" />
			    	<h4>Novaerus Video + Animation</h4>
			    </div>
			</div>
			<ContentContain>
				<div className={IndexStyles.indexAbout}>
					<h2>Lets Connect</h2>
					<p>I have been working as a Freelance Creative for over a decade now, I have a wide array of skills that allow mme to bring a unique perspective  to my project</p>
					<p><span className={IndexStyles.contactEmail}>/</span> mmallory80@gmail.com</p>
					<p><span className={IndexStyles.contactEmail}>/</span> 480.290.1726</p>
				</div>
				<Footer />			
			</ContentContain>
		</Layout>
	)
}

export default IndexPage
